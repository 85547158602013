import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AppContext } from "../../store/store";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  additionalSetupCost,
  additionalValue,
  defaultFormatCurrencyAmount,
  formatCurrencyAmount as formatFn,
  phoneNumberPrice,
  tollFreeValue,
} from "../../utils/Constants";
import {
  codeCurrencyMap,
  codeMap,
  planAmountMap,
  planEnum,
} from "../../utils/Country";
import CustomButton from "../CustomButton";
// import useMediaQuery from "@mui/material";

const SummaryPopup = ({
  isPopup = true,
  isAddons = false,
  first_name = "John",
  last_name = "Smith",
  email = "hello@hotmail.com",
  phone = "0434105689",
  location,
  no_of_user = "$0.00",
  headset1 = 0,
  headset2 = 0,
  headset3 = 0,
  handset = 0,
  ivr_flow,
  ivr_flow_value,
  meeting_device,
  basic_call_user,
  premium_call_user,
  set_up_value,
  selected_plan,
  isFullScreen,
  total_ivr_flow,
}) => {
  const [isSummaryModal, setSummaryModal] = useState(!isPopup);
  const matchesHeight = useMediaQuery("(max-height: 720px)");
  const { currency, conversionRates } = useContext(AppContext);

  const formatCurrencyAmount = formatFn.bind(conversionRates);

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      flexDirection={"column"}
      alignItems={"flex-end"}
    >
      {isPopup && (
        <CustomButton
          sx={{
            py: "18px",
            display: {
              md: matchesHeight && !isFullScreen ? "flex" : "none",
              xs: "flex",
            },
          }}
          variant={"contained"}
          btnText={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="30"
              height="30"
              x="0"
              y="0"
              viewBox="0 0 512 512"
            >
              <g>
                <path
                  d="M498.791 161.127c-17.545-17.546-46.094-17.545-63.645.004l-45.128 45.166V87.426c0-12.02-4.681-23.32-13.181-31.819L334.412 13.18C325.913 4.68 314.612 0 302.592 0H45.018c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45V333.631L498.79 224.767c17.587-17.586 17.591-46.052.001-63.64zM300.019 30c2.834 0 8.295-.491 13.18 4.393l42.426 42.427c4.76 4.761 4.394 9.978 4.394 13.18h-60V30zm59.999 437c0 8.271-6.728 15-15 15h-300c-8.271 0-15-6.729-15-15V45c0-8.271 6.729-15 15-15h225v75c0 8.284 6.716 15 15 15h75v116.323l-44.256 44.293-21.203 21.204a15 15 0 0 0-3.624 5.863l-21.214 63.64a14.999 14.999 0 0 0 18.974 18.974l63.64-21.213a14.988 14.988 0 0 0 5.863-3.624l1.82-1.82V467zm-33.64-154.573 21.213 21.213-8.103 8.103-31.819 10.606 10.606-31.82 8.103-8.102zm42.422-.005-21.213-21.213 72.105-72.166 21.213 21.213-72.105 72.166zm108.773-108.864-15.463 15.476-21.213-21.213 15.468-15.481c5.852-5.849 15.366-5.848 21.214 0 5.847 5.85 5.878 15.333-.006 21.218z"
                  fill="white"
                  opacity="1"
                  data-original="white"
                ></path>
                <path
                  d="M285.018 150h-210c-8.284 0-15 6.716-15 15s6.716 15 15 15h210c8.284 0 15-6.716 15-15s-6.716-15-15-15zM225.018 210h-150c-8.284 0-15 6.716-15 15s6.716 15 15 15h150c8.284 0 15-6.716 15-15s-6.716-15-15-15zM225.018 270h-150c-8.284 0-15 6.716-15 15s6.716 15 15 15h150c8.284 0 15-6.716 15-15s-6.716-15-15-15zM225.018 330h-150c-8.284 0-15 6.716-15 15s6.716 15 15 15h150c8.284 0 15-6.716 15-15s-6.716-15-15-15zM285.018 422h-90c-8.284 0-15 6.716-15 15s6.716 15 15 15h90c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                  fill="white"
                  opacity="1"
                  data-original="white"
                ></path>
              </g>
            </svg>
          }
          onClick={() => setSummaryModal(!isSummaryModal)}
        />
      )}

      <Stack
        sx={{
          width: isPopup ? "210px" : "100%",
          borderRadius: "13px",
          background: "#FFF",
          px: "16px",
          pt: "10px",
          mt: "10px",
          display: {
            md:
              !matchesHeight || isSummaryModal || isFullScreen
                ? "flex"
                : "none",
            xs: isSummaryModal ? "flex" : "none",
          },
          pb: "17px",
        }}
      >
        <Typography
          className="bigText"
          sx={{
            color: "#5E718B",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "160%",
            fontFamily: "Heebo",
          }}
        >
          Summary
        </Typography>
        <Box marginTop={"5px"}>
          <Accordion sx={{ border: "none", boxShadow: "none" }}>
            <AccordionSummary
              sx={{ padding: 0 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography
                className="bigText"
                sx={{
                  color: "#5E718B",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "160%",
                  fontFamily: "Heebo",
                }}
              >
                Customer Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Typography
                className="smallText"
                sx={{
                  color: "#666",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontFamily: "Heebo",
                  lineHeight: "160%",
                }}
              >
                {first_name} {last_name}
                <br />
                {email}
                <br />
                {phone}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <span
          style={{
            width: "100%",
            minHeight: "0.25px",
            background: "#7B83EB",
            marginTop: "2px",
            marginBottom: "5px",
          }}
        ></span>
        <Accordion sx={{ border: "none", boxShadow: "none" }} defaultExpanded>
          <AccordionSummary
            sx={{ padding: 0 }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography
              className="bigText"
              sx={{
                color: "#5E718B",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "160%",
                fontFamily: "Heebo",
                mb: "4px",
              }}
            >
              Monthly Costs
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box
              sx={{
                height: "auto",
                // maxHeight: isFullScreen ? "none" : "126px",
                // overflow: "auto",
                px: "5px",
              }}
              display={"flex"}
              className="scroll-bar summary-popup-monthly-costs-section"
              flexDirection={"column"}
            >
              {location.map((item, index) => (
                <React.Fragment key={item?.plan?.name + 11 + index}>
                  {index !== 0 && (
                    <span
                      key={item?.plan?.name + 11 + index}
                      style={{
                        width: "100%",
                        minHeight: "0.25px",
                        background: "#7B83EB",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    ></span>
                  )}
                  <Accordion
                    sx={{ border: "none", boxShadow: "none" }}
                    defaultExpanded
                  >
                    <AccordionSummary
                      sx={{ padding: 0 }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography
                        className="midText"
                        sx={{
                          color: "#5E718B",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                          mb: "6px",
                        }}
                      >
                        {codeMap[item.country_code]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <Box key={item?.plan?.name + index}>
                        {item?.number_of_users > 0 && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            gap="5px"
                          >
                            <Typography
                              className="smallText"
                              sx={{
                                color: "#666",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontFamily: "Heebo",
                                lineHeight: "160%",
                              }}
                            >
                              {item?.plan?.name} Plans X {item?.number_of_users}
                            </Typography>
                            <Typography
                              className="smallBoldText"
                              sx={{
                                color: "#666",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "160%",
                                fontFamily: "Heebo",
                              }}
                            >
                              {defaultFormatCurrencyAmount(
                                planAmountMap[item?.country_code || "US"][
                                  planEnum[item.plan.name]
                                ] * parseFloat(item?.number_of_users),
                                currency,
                                2,
                                item?.country_code
                              )}
                              {/* {formatCurrencyAmount(parseFloat(item?.plan?.price) * parseFloat(item?.number_of_users), currency, 2)} */}
                            </Typography>
                          </Box>
                        )}
                        {item?.numbers_to_port > 0 && (
                          <>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              gap="5px"
                            >
                              <Typography
                                className="smallText"
                                sx={{
                                  color: "#666",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  fontFamily: "Heebo",
                                }}
                              >
                                Ported numbers X {item?.numbers_to_port}
                              </Typography>
                              <Typography
                                className="smallBoldText"
                                sx={{
                                  color: "#666",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "160%",
                                  fontFamily: "Heebo",
                                }}
                              >
                                {defaultFormatCurrencyAmount(
                                  item?.plan?.name === "Starter"
                                    ? item?.numbers_to_port
                                    : item?.numbers_to_port * 0.6,
                                  currency,
                                  2,
                                  item.country_code
                                )}
                              </Typography>
                            </Box>{" "}
                          </>
                        )}
                        {item?.additional_numbers > 0 && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            gap="5px"
                          >
                            <Typography
                              className="smallText"
                              sx={{
                                color: "#666",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "160%",
                                fontFamily: "Heebo",
                              }}
                            >
                              Additional numbers X {item?.additional_numbers}
                            </Typography>
                            <Typography
                              className="smallBoldText"
                              sx={{
                                color: "#666",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "160%",
                                fontFamily: "Heebo",
                              }}
                            >
                              {defaultFormatCurrencyAmount(
                                (item?.additional_numbers / 10) *
                                  additionalValue(
                                    item?.plan?.name,
                                    codeCurrencyMap[item?.country_code]
                                  ),
                                currency,
                                2,
                                item?.country_code
                              )}
                            </Typography>
                          </Box>
                        )}{" "}
                        {item?.is_toll_free_required && (
                          <>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              gap="5px"
                            >
                              <Typography
                                className="smallText"
                                sx={{
                                  color: "#666",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  fontFamily: "Heebo",
                                }}
                              >
                                Toll free numbers X{" "}
                                {item?.total_toll_free_numbers}
                              </Typography>
                              <Typography
                                className="smallBoldText"
                                sx={{
                                  color: "#666",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "160%",
                                  fontFamily: "Heebo",
                                }}
                              >
                                {defaultFormatCurrencyAmount(
                                  item?.total_toll_free_numbers *
                                    tollFreeValue(
                                      false,
                                      codeCurrencyMap[item?.country_code]
                                    ),
                                  currency,
                                  2,
                                  item?.country_code
                                )}
                              </Typography>
                            </Box>{" "}
                          </>
                        )}
                      </Box>
                    </AccordionDetails>{" "}
                  </Accordion>{" "}
                </React.Fragment>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        {isAddons && (
          <React.Fragment>
            <span
              style={{
                width: "100%",
                minHeight: "0.25px",
                background: "#7B83EB",
                marginTop: "1px",
                marginBottom: "5px",
              }}
            ></span>

            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                sx={{ padding: 0 }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  className="bigText"
                  sx={{
                    color: "#5E718B",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "160%",
                    mb: "4px",
                  }}
                >
                  Additional Monthly Costs
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ padding: 0 }}>
                <div>
                  {parseInt(total_ivr_flow) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontFamily: "Heebo",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                        }}
                      >
                        Call flows X {total_ivr_flow}
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontFamily: "Heebo",
                          lineHeight: "160%",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(total_ivr_flow) * 10,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {parseInt(basic_call_user) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontFamily: "Heebo",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                        }}
                      >
                        Basic Call recording X {basic_call_user}
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontFamily: "Heebo",
                          lineHeight: "160%",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(basic_call_user) * 20,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {premium_call_user > 0 && (
                    <Box
                      display={"flex"}
                      gap={"5px"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontFamily: "Heebo",
                          lineHeight: "160%",
                        }}
                      >
                        Premium Call recording X {premium_call_user}
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontFamily: "Heebo",
                          fontWeight: "700",
                          lineHeight: "160%",
                        }}
                      >
                        {formatCurrencyAmount(
                          premium_call_user * 49,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        )}

        {!isAddons && (
          <>
            <span
              style={{
                width: "100%",
                minHeight: "0.5px",
                backgroundColor: "#7B83EB",
                marginTop: "1px",
              }}
            ></span>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                sx={{ padding: 0 }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  className="bigText"
                  sx={{
                    color: "#5E718B",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "160%",
                    mb: "4px",
                  }}
                >
                  Once Off Costs
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ padding: 0 }}>
                <div>
                  {location.find((item) => item?.numbers_to_port > 0) && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="5px"
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Porting set up cost
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {selected_plan === 1
                          ? "Free"
                          : selected_plan === 2
                          ? defaultFormatCurrencyAmount(
                              phoneNumberPrice.port / 2,
                              currency,
                              2,
                              location[0].country_code
                            )
                          : defaultFormatCurrencyAmount(
                              phoneNumberPrice.port,
                              currency,
                              2,
                              location[0].country_code
                            )}
                      </Typography>
                    </Box>
                  )}
                  {location.find(
                    (item) => item?.is_toll_free_required === true
                  ) && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="5px"
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Toll free numbers set up cost
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {selected_plan === 1
                          ? "Free"
                          : selected_plan === 2
                          ? defaultFormatCurrencyAmount(
                              tollFreeValue(true, currency) / 2,
                              currency,
                              2,
                              location[0].country_code
                            )
                          : defaultFormatCurrencyAmount(
                              tollFreeValue(true, currency),
                              currency,
                              2,
                              location[0].country_code
                            )}
                      </Typography>
                    </Box>
                  )}
                  {location.find((item) => item?.additional_numbers > 0) && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="5px"
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Additional number setup cost
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {defaultFormatCurrencyAmount(
                          additionalSetupCost(
                            currency,
                            parseInt(
                              location.reduce(
                                (sum, product) =>
                                  sum + parseInt(product.additional_numbers),
                                0
                              )
                            )
                          ),
                          currency,
                          2,
                          location[0].country_code
                        )}
                      </Typography>
                    </Box>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {isAddons && (
          <>
            <span
              style={{
                width: "100%",
                minHeight: "0.25px",
                backgroundColor: "#7B83EB",
                marginTop: "1px",
                marginBottom: "1px",
              }}
            ></span>

            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                sx={{ padding: 0 }}
                expandIcon={<ExpandMoreIcon />}
              >
                {" "}
                <Typography
                  className="bigText"
                  sx={{
                    color: "#5E718B",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "160%",
                    mb: "4px",
                  }}
                >
                  Once Off Costs
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Box>
                  {location.find((item) => item?.numbers_to_port > 0) && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="5px"
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Porting set up cost
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {selected_plan === 1
                          ? "Free"
                          : selected_plan === 2
                          ? defaultFormatCurrencyAmount(
                              phoneNumberPrice.port / 2,
                              currency,
                              2,
                              location[0].country_code
                            )
                          : defaultFormatCurrencyAmount(
                              phoneNumberPrice.port,
                              currency,
                              2,
                              location[0].country_code
                            )}
                      </Typography>
                    </Box>
                  )}
                  {location.find(
                    (item) => item?.is_toll_free_required === true
                  ) && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="5px"
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Toll free numbers set up cost
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {selected_plan === 1
                          ? "Free"
                          : selected_plan === 2
                          ? defaultFormatCurrencyAmount(
                              tollFreeValue(true, currency) / 2,
                              currency,
                              2,
                              location[0].country_code
                            )
                          : defaultFormatCurrencyAmount(
                              tollFreeValue(true, currency),
                              currency,
                              2,
                              location[0].country_code
                            )}
                      </Typography>
                    </Box>
                  )}
                  {location.find((item) => item?.additional_numbers > 0) && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap="5px"
                    >
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Additional number setup cost
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {defaultFormatCurrencyAmount(
                          additionalSetupCost(
                            currency,
                            parseInt(
                              location.reduce(
                                (sum, product) =>
                                  sum + parseInt(product.additional_numbers),
                                0
                              )
                            )
                          ),
                          currency,
                          2,
                          location[0].country_code
                        )}
                      </Typography>
                    </Box>
                  )}
                  {ivr_flow === true &&
                    ivr_flow_value !== "free" &&
                    selected_plan !== 1 && (
                      <Box
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          className="smallText"
                          sx={{
                            color: "#666",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            fontFamily: "Heebo",
                            maxWidth: "110px",
                          }}
                        >
                          Automated attendant & IVR call flow set up cost
                        </Typography>
                        <Typography
                          className="smallBoldText"
                          sx={{
                            color: "#666",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "160%",
                            fontFamily: "Heebo",
                          }}
                        >
                          {ivr_flow === true && ivr_flow_value !== "free"
                            ? selected_plan === 1
                              ? "0.00"
                              : selected_plan === 2
                              ? formatCurrencyAmount(247.5, currency)
                              : formatCurrencyAmount(495, currency)
                            : "0.00"}
                        </Typography>
                      </Box>
                    )}
                  {parseInt(headset1) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Black Headset
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(headset1) * 130,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {parseInt(headset2) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Blue Headset
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(headset2) * 490,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {parseInt(headset3) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Orange Headset
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(headset3) * 400,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {parseInt(handset) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Handsets
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(handset) * 490,
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {parseInt(meeting_device) > 0 && (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        className="smallText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        Meeting room devices
                      </Typography>
                      <Typography
                        className="smallBoldText"
                        sx={{
                          color: "#666",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "160%",
                          fontFamily: "Heebo",
                        }}
                      >
                        {formatCurrencyAmount(
                          parseInt(meeting_device),
                          currency,
                          2
                        )}
                      </Typography>
                    </Box>
                  )}
                  {/* <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  sx={{
                    color: "#666",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "160%",
                    fontFamily: "Heebo",
                  }}
                >
                  Porting
                </Typography>
                <Typography
                  sx={{
                    color: "#666",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Heebo",
                    lineHeight: "160%",
                  }}
                >
                  {location?.reduce(
                    (a, b) => Number(a) + Number(b.numbers_to_port),
                    0
                  ) * 2}
                </Typography>
              </Box> */}
                  {/* <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  sx={{
                    color: "#666",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontFamily: "Heebo",
                    fontWeight: "400",
                    lineHeight: "160%",
                  }}
                >
                  Number provisioning
                </Typography>
                <Typography
                  sx={{
                    color: "#666",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Heebo",
                    lineHeight: "160%",
                  }}
                >
                  $
                  {location?.reduce(
                    (a, b) => Number(a) + Number(b.additional_numbers),
                    0
                  ) * 10}
                  .00
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  sx={{
                    color: "#666",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Heebo",
                    lineHeight: "160%",
                  }}
                >
                  IVR call flow set up
                </Typography>
                <Typography
                  sx={{
                    color: "#666",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Heebo",
                    lineHeight: "160%",
                  }}
                >
                  $
                  {location?.reduce(
                    (a, b) => Number(a) + Number(b.total_toll_free_numbers),
                    0
                  ) * 10}
                  .00
                </Typography>
              </Box> */}
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Stack>
    </Box>
  );
};
export default SummaryPopup;
