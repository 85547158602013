import {
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import CustomRadioButton from "../../components/CustomRadioButton";
import InputText from "../../components/InputText/InputText";

const Summary = ({ signupdetails, handleChange }) => {
  const [selectedOption, setSelectedOption] = useState(
    signupdetails?.shipping === "One Address"
      ? "one_address"
      : signupdetails?.shipping === "Same as Above"
      ? "same_as_above"
      : "multiple_address"
  );

  const handleRadioChange = (key, event) => {
    setSelectedOption(event.target.value);
    handleChange(
      "shipping",
      key === 1
        ? "One Address"
        : key === 2
        ? "Same as Above"
        : "Multiple Addresses"
    );
  };
  return (
    <Stack width={"100%"}>
      <Box sx={{ overflow: "auto" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "1fr 1fr 0.5fr", xs: "1.5fr" },
            gap: { md: "34px", xs: "10px" },
          }}
        >
          <InputText
            required
            placeholder={"Company Name"}
            name="company_name"
            value={signupdetails?.company_name}
            onChange={(event) =>
              handleChange("company_name", event.target.value)
            }
          />
          <InputText
            required
            type="number"
            value={signupdetails?.business_reg_no}
            placeholder={"Business Registration Number"}
            name="business_reg_no"
            onChange={(event) =>
              handleChange("business_reg_no", event.target.value)
            }
          />
          <span></span>

          <InputText
            required
            placeholder={"Address"}
            name="address"
            value={signupdetails?.address}
            onChange={(event) => handleChange("address", event.target.value)}
          />
          <InputText
            required
            placeholder="City"
            name="city"
            value={signupdetails?.city}
            onChange={(event) => handleChange("city", event.target.value)}
          />
          <span></span>

          <InputText
            required
            placeholder="Post Code"
            name="post_code"
            type="number"
            value={signupdetails?.post_code}
            onChange={(event) => handleChange("post_code", event.target.value)}
          />
          <InputText
            required
            placeholder="Region"
            name="region"
            value={signupdetails?.region}
            onChange={(event) => handleChange("region", event.target.value)}
          />
          <span></span>
        </Box>

        <FormControl
          fullWidth
          className={"customRadio"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{
              color: "text.secondary",
              fontSize: "14px",
              fontWeight: "400",
              pl: "3px",
              whiteSpace: "nowrap",
            }}
          >
            Shipping <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <RadioGroup
            row
            sx={{ m: 1, ml: "13px" }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <CustomRadioButton
              label="One Address"
              checked={selectedOption === "one_address"}
              onChange={(event) => handleRadioChange(1, event)}
              value="one_address"
            />
            <CustomRadioButton
              label="Same as Above"
              checked={selectedOption === "same_as_above"}
              onChange={(event) => handleRadioChange(2, event)}
              value="same_as_above"
            />
            {/* <CustomRadioButton
              label="Multiple Addresses"
              checked={selectedOption === "multiple_address"}
              onChange={(event) => handleRadioChange(3, event)}
              value="multiple_address"
            /> */}
          </RadioGroup>
        </FormControl>
        {selectedOption === "multiple_address" && (
          <>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { md: "1fr 1fr 0.5fr", xs: "1.5fr" },
                gap: { md: "34px", xs: "10px" },
                mb: "33px",
              }}
            >
              <InputText
                required
                placeholder="Shipping Address"
                name="shipping_address"
                value={signupdetails.shipping_address}
                onChange={(event) =>
                  handleChange("shipping_address", event.target.value)
                }
              />
              <InputText
                required
                placeholder="Shipping City"
                name="shipping_city"
                value={signupdetails.shipping_city}
                onChange={(event) =>
                  handleChange("shipping_city", event.target.value)
                }
              />
              <span></span>

              <InputText
                required
                placeholder="Post Code"
                name="shipping_post_code"
                value={signupdetails.shipping_post_code}
                onChange={(event) =>
                  handleChange("shipping_post_code", event.target.value)
                }
              />
              <InputText
                required
                placeholder="Region"
                name="shipping_region"
                value={signupdetails.shipping_region}
                onChange={(event) =>
                  handleChange("shipping_region", event.target.value)
                }
              />
              <span></span>
            </Box>
          </>
        )}
        <Box className={"signUp-btn-wrap"}>
          <Button
            variant={!signupdetails?.compete_signup ? "outlined" : "contained"}
            className={"signUp-btn"}
            sx={{
              borderRadius: "14.5px",
              border: "1px solid #7B83EB",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "160%",
              textTransform: "none",
              px: "12px",
              py: "5px",
              mb: "10px",
              ml: "5px",
            }}
            onClick={() =>
              handleChange("compete_signup", !signupdetails?.compete_signup)
            }
          >
            Not Ready? Start your free trial
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};
export default Summary;
