import { ReactComponent as Australia } from "../assets/flag/australia.svg";
import { ReactComponent as UnitedStates } from "../assets/flag/unitedStates.svg";
import { ReactComponent as Canada } from "../assets/flag/canada.svg";
import { ReactComponent as NewZealand } from "../assets/flag/newZealand.svg";
import { ReactComponent as Singapore } from "../assets/flag/singapore.svg";
import { ReactComponent as UnitedKingdom } from "../assets/flag/unitedKingdom.svg";
import React from "react";
import USPlanInfo from "../assets/png/us_plan.png";
import UKPlanInfo from "../assets/png/uk_plan.png";
import ATPlanInfo from "../assets/png/au_plan.png";
import NZPlanInfo from "../assets/png/nz_plan.png";
import SGPlanInfo from "../assets/png/sg_plan.png";

export const CONV_API_URL =
  "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_EIAD04yYS0Sn0jM31B3tmEcDhGdJW3XmHvsO6nTr&currencies=USD%2CAUD%2CCAD%2CNZD%2CSGD%2CGBP";

export const fetchConversionRates = async () => {
  try {
    const res = await fetch(CONV_API_URL);
    const data = await res.json();
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

const country = [
  {
    name: "United States",
    code: "US",
    capital: "Washington, D.C.",
    region: "NA",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
    flag: <UnitedStates />,
  },
  {
    name: "Australia",
    code: "AU",
    capital: "Vienna",
    region: "EU",
    currency: {
      code: "AUD",
      name: "Australian Dollar",
      symbol: "AU$",
    },
    language: {
      code: "de",
      name: "German",
    },
    flag: <Australia />,
  },
  {
    name: "Canada",
    code: "CA",
    capital: "Ottawa",
    region: "NA",
    currency: {
      code: "CAD",
      name: "Canadian dollar",
      symbol: "CA$",
    },
    language: {
      code: "en",
      name: "English",
    },
    flag: <Canada />,
  },
  {
    name: "New Zealand",
    code: "NZ",
    capital: "Wellington",
    region: "OC",
    currency: {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "NZ$",
    },
    language: {
      code: "en",
      name: "English",
    },
    flag: <NewZealand />,
  },
  {
    name: "Singapore",
    code: "SG",
    capital: "Singapore",
    region: "AS",
    currency: {
      code: "SGD",
      name: "Singapore dollar",
      symbol: "S$",
    },
    language: {
      code: "en",
      name: "English",
    },
    flag: <Singapore />,
  },
  {
    name: "United Kingdom",
    code: "GB",
    capital: "London",
    region: "EU",
    currency: {
      code: "GBP",
      name: "British pound",
      symbol: "£",
    },
    language: {
      code: "en",
      name: "English",
    },
    flag: <UnitedKingdom />,
  },
];

const codeMapObj = {};
const currencyMapObj = {};
const codeCurrencyMapObj = {};

country.forEach((item) => {
  currencyMapObj[item.currency.code] = item.currency.symbol;
  codeMapObj[item.code] = item.name;
  codeCurrencyMapObj[item.code] = item?.currency.code;
});

export const currencyMap = currencyMapObj;
export const codeMap = codeMapObj;
export const codeCurrencyMap = codeCurrencyMapObj;

export const planImgMap = {
  US: USPlanInfo,
  GB: UKPlanInfo,
  AU: ATPlanInfo,
  SG: SGPlanInfo,
  NZ: NZPlanInfo,
  CA: USPlanInfo,
};

export const planEnum = {
  Starter: "starter",
  Essential: "essl",
  Professional: "pro",
};

export const planAmountMap = {
  US: {
    starter: 2,
    essl: 3,
    pro: 6,
  },
  GB: {
    starter: 2,
    essl: 8,
    pro: 12,
  },
  AU: {
    starter: 4,
    essl: 12,
    pro: 19,
  },
  SG: {
    starter: 4,
    essl: 12,
    pro: 19,
  },
  NZ: {
    starter: 4,
    essl: 12,
    pro: 19,
  },
  CA: {
    starter: 4,
    essl: 12,
    pro: 19,
  },
};

export default country;
