/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  InputBase,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  flexGrow: 1,
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 13,
    position: "relative",
    border: "1px solid",
    borderColor: "#7B83EB",
    // color: '#33388d',
    fontSize: 14,
    width: "90%",
    height: "auto",
    marginLeft: "5px",
    padding: "8px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.ochre.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.ochre.main,
      borderRadius: 13,
    },
    "&:placeholder": {
      color: "#5E718B",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "160%",
    },
  },
}));

const PhoneInputText = ({
  label,
  width = "100%",
  refInput,
  placeholder,
  defaultValue,
  isRequiredIcon = true,
  name,
  onPhoneNumberChange,
  ...props
}) => {
  const defaultTelCode = localStorage.getItem("telCode") || "US";
  const [telCode, setTelCode] = useState(defaultTelCode);
  const [phoneNumber, setPhoneNumber] = useState(defaultValue);

  useEffect(() => {
    phoneNumberChangeHelper(defaultValue, defaultTelCode);
  }, []);

  function handleTelCodeChange(e) {
    const enteredTelCode = e.target.value;
    setTelCode(enteredTelCode);
    localStorage.setItem("telCode", enteredTelCode);
    phoneNumberChangeHelper(phoneNumber, enteredTelCode);
  }

  function handleInputChange(e) {
    const enteredPhoneNumber = e.target.value;
    setPhoneNumber(enteredPhoneNumber);
    phoneNumberChangeHelper(enteredPhoneNumber, telCode);
  }

  function phoneNumberChangeHelper(phone, countryCode) {
    const fullNumber = `+${getCountryCallingCode(countryCode)}${phone}`;

    const isValid = isValidPhoneNumber(fullNumber, countryCode);
    onPhoneNumberChange({
      fullNumber,
      phoneNumber: phone,
      isValid,
    });
  }

  return (
    <div>
      <Typography
        htmlFor="bootstrap-input"
        sx={{
          color: "text.secondary",
          fontSize: "14px !important",
          fontWeight: "400",
          marginBottom: "10px",
          marginLeft: "5px",
          lineHeight: "160%",
          fontStyle: "normal",
          fontFamily: "Heebo",
        }}
      >
        {label ? label : placeholder}{" "}
        {isRequiredIcon && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Select
          className="country-select app-select"
          sx={{ borderRadius: "13px", flexGrow: 0 }}
          id="country-code"
          onChange={handleTelCodeChange}
          value={telCode}
          input={<BootstrapInput />}
        >
          {getCountries().map((country) => (
            <MenuItem
              sx={{ justifyContent: "space-between", gap: "10px" }}
              key={country}
              value={country}
            >
              <Box gap="5px" display="flex" alignItems="center">
                <ReactCountryFlag svg countryCode={country} />{" "}
                <Typography fontSize="14px" className="country-name">
                  {countryList().getLabel(country)}
                </Typography>
              </Box>
              <Typography fontSize="14px">
                +{getCountryCallingCode(country)}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <FormControl sx={{ flexGrow: 1 }} variant="standard" width={width}>
          <BootstrapInput
            defaultValue={defaultValue}
            required
            refs={refInput}
            placeholder={placeholder}
            width={width}
            onChange={handleInputChange}
            {...props}
          />
        </FormControl>
      </Box>
    </div>
  );
};
export default PhoneInputText;
