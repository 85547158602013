import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import StripeCheckout from "./components/StripeCheckout/StripeCheckout";
import NotFound from "./pages/NotFound";
import Signup from "./pages/Signup";
import { AppContext } from "./store/store";
import { dummyConversionRates } from "./utils/Constants";
import theme from "./utils/ThemeColors";

const App = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("selectedCurrency") || "USD"
  );
  const [conversionRates, setConversionRates] = useState(
    dummyConversionRates["USD"]
  );
  const [defaultConversionRates, setDefaultConversionRates] =
    useState(dummyConversionRates);
  const initialData = {
    currency: selectedCurrency,
    conversionRates,
    setCurrencyData(payload) {
      setConversionRates(payload);
    },
    defaultConversionRates,
    setDefaultConversion(payload) {
      setDefaultConversionRates(payload);
    },
    setCurrency(payload) {
      localStorage.setItem("selectedCurrency", payload);
      setSelectedCurrency(payload);
    },
  };
  return (
    <AppContext.Provider value={initialData}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signup />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/checkout" element={<StripeCheckout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
