import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useContext } from "react";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import CustomSwitch from "../CustomSwitch";
import { formatCurrencyAmount as formatFn } from "../../utils/Constants";
import { AppContext } from "../../store/store";
import CustomCounter from "../CustomCounter";
import IvrCall from "../../assets/png/ivr_call.png";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className, arrow: className.arrow }}
  />
))(({ theme }) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    background: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "10px",
    boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.3)",
    width: "180px",
    textAlign: "center",
    lineHeight: "150%",
    fontSize: 12,
  },
}));

const AutomatedView = React.forwardRef(
  ({ isFullScreen, handleChange, signupdetails }, ref) => {
    const { currency, conversionRates } = useContext(AppContext);
    const formatCurrencyAmount = formatFn.bind(conversionRates);

    return (
      <Stack
        className="accordionContent"
        minHeight={isFullScreen ? 0 : "156px"}
        flexWrap={"wrap"}
        ref={ref}
        marginBottom={"30px"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "Heebo",
            fontWeight: "600",
            color: "#4f555d",
            mb: "8px",
          }}
        >
          Automated Attendants and IVR Call Flows
        </Typography>
        <CustomSwitch
          labelName={"Do you need IVR call flow?"}
          isRequired={true}
          imageTooltip={true}
          tooltipTitle={
            <Box
              width={995}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  style={{ width: "300px" }}
                  src={IvrCall}
                  alt={`${IvrCall} plan`}
                ></img>
                <Typography
                  sx={{
                    ml: "8px",
                    color: "text.secondary",
                    fontSize: "14px",
                    fontWeight: "400",
                    paddingRight: "10px",
                    display: "flex",
                    fontFamily: "Heebo",
                    alignItems: "center",
                  }}
                >
                  - IVR (Interactive Voice Response) is an automated system that
                  interacts with callers using voice prompts and touch-tone
                  keypad entries to route calls, provide information or collect
                  data from the caller.
                  <br></br>- The IVR call flow typically starts with a greeting
                  message that welcomes the caller and provides options for them
                  to choose from, such as "Press 1 for customer support, press 2
                  for billing inquiries, etc." The caller then selects an option
                  by pressing the corresponding number on their phone keypad.
                  <br></br>- Based on the caller's input, the IVR system then
                  proceeds to the appropriate next step, which could include
                  providing more detailed menu options, asking the caller to
                  enter their account number or other personal information, or
                  transferring the call to a live agent. <br></br>- Throughout
                  the call flow, the IVR system may use pre-recorded messages or
                  dynamically generated prompts to provide information or
                  request input from the caller. The goal of the IVR call flow
                  is to efficiently route calls to the correct destination and
                  provide a smooth, user-friendly experience for the caller.
                </Typography>
              </Box>
            </Box>
          }
          name={isFullScreen ? "ivr_flow_full" : "ivr_flow"}
          checked={signupdetails?.ivr_flow}
          signupdetails={signupdetails}
          handleOnChange={() =>
            handleChange("ivr_flow", !signupdetails.ivr_flow)
          }
        />
        {signupdetails?.ivr_flow && (
          <>
            <Box
              className="setupBox"
              display={"flex"}
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              flexWrap={"wrap"}
            >
              <Stack
                onClick={() => handleChange("ivr_flow_value", "free")}
                sx={{
                  display: "flex",
                  borderRadius: "13px",
                  border: "1px solid #7B83EB",
                  background:
                    signupdetails?.ivr_flow_value === "free"
                      ? "#8EE3F5"
                      : "#FFF",
                  color:
                    signupdetails?.ivr_flow_value === "free" ? "#FFF" : "#666",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "10px",
                  px: "11px",
                  width: "126px",
                  cursor: "pointer",
                  mt: "5px",
                  mr: "10px",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: " 14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontFamily: "Heebo",
                      lineHeight: "160%",
                      mx: "5px",
                    }}
                  >
                    Self-Setup
                  </Typography>
                  <LightTooltip
                    title={
                      "You can set this up yourself using the automated builder within our portal."
                    }
                    placement="top"
                  >
                    <Info style={{ marginLeft: "5px" }} />
                  </LightTooltip>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontFamily: "Heebo",
                    lineHeight: "160%",
                  }}
                >
                  Free
                </Typography>
              </Stack>
              <Stack
                onClick={() => handleChange("ivr_flow_value", "curated")}
                sx={{
                  display: "flex",
                  borderRadius: "13px",
                  border: "1px solid #7B83EB",
                  background:
                    signupdetails?.ivr_flow_value === "curated"
                      ? "#8EE3F5"
                      : "#FFF",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "11px",
                  px: "11px",
                  // width: "136px",
                  cursor: "pointer",
                  mt: "6px",
                  color:
                    signupdetails?.ivr_flow_value === "curated"
                      ? "#FFF"
                      : "#666",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: " 14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      fontFamily: "Heebo",
                      mx: "5px",
                    }}
                  >
                    Curated Setup
                  </Typography>
                  <LightTooltip
                    title={
                      "Our team of engineers will take you through a workshop to curate and customise your call flow. "
                    }
                    placement="top"
                  >
                    <Info style={{ marginLeft: "5px" }} />
                  </LightTooltip>
                </Box>
                <Box display={"flex"}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontFamily: "Heebo",
                      lineHeight: "160%",
                    }}
                  >
                    {signupdetails?.selected_plan === 1
                      ? "Free"
                      : signupdetails?.selected_plan === 2
                      ? formatCurrencyAmount(247.5, currency)
                      : formatCurrencyAmount(495, currency)}
                  </Typography>
                  {signupdetails?.selected_plan !== 3 &&
                    signupdetails?.selected_plan !== 4 && (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontFamily: "Heebo",
                          fontWeight: "700",
                          lineHeight: "160%",
                          ml: "5px",
                        }}
                      >
                        <del>{formatCurrencyAmount(495, currency)}</del>
                      </Typography>
                    )}
                </Box>
              </Stack>
            </Box>
            <CustomCounter
              labelName={"How many call flows do you need"}
              placeholder={"0"}
              min={0}
              boxStyle={{ mt: "12px" }}
              tooltipTitle={
                "How many users do you want to be able to make calls?"
              }
              isInfoIcon={false}
              value={signupdetails?.total_ivr_flow || 0}
              onHandleChange={(value) => handleChange("total_ivr_flow", value)}
            />
          </>
        )}
      </Stack>
    );
  }
);

export default AutomatedView;
