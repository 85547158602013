import {
  codeCurrencyMap,
  currencyMap,
  planAmountMap,
  planEnum,
} from "./Country";

export const CURRENCY_SYMBOL = "$";

export const RESULTS_PER_PAGE = 25;

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

export const decimalNumberRegex = (number) => {
  return /^\d+(\.\d{1,2})?$/.test(number);
};

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const removeLocalStorageValue = (key) => {
  localStorage.removeItem(key);
};

export const flushLocalStorage = () => {
  localStorage.clear();
};

export const flushSessionStorage = () => {
  sessionStorage.clear();
};

export const endPoints = {
  signup: "/signup",
  location: "/location",
};

export const STRIPE_KEY =
  "pk_test_51IxkYnE2SdKoR9r8gM6fOtgnWa5d18f8hTeyKmyWNzvWdrLE2rZnTAtKjE9yEmVlxOUQ7kWenaOwHVb3nV7esVYF00TKBQkY56";

export const labelList = [
  {
    label: "Please enter your details",
  },
  {
    label: "Choose your primary location",
  },
  {
    label: "Choose your addons",
  },
  {
    label: "Please enter your details",
  },
  {
    label: "",
  },
];

export const signupNullDetails = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  company_name: "",
  is_selected_country: false,
  compete_signup: false,
  shipping_region: "",
  shipping_post_code: "",
  shipping_city: "",
  shipping_address: "",
  region: "",
  post_code: "",
  city: "",
  address: "",
  business_reg_no: "",
  billing_company_name: "",
  shipping: "One Address",
  ivr_flow: true,
  ivr_flow_value: "free",
  basic_call_user: 0,
  premium_call_user: 0,
  headset1: 0,
  headset2: 0,
  headset3: 0,
  handset: 0,
  total_headset_value: 0,
  total_handset_value: 0,
  set_up_value: 0,
  porting: 0,
  number_provisioning: 0,
  inr_call_flow_set_up: 0,
  no_of_user: 0,
  basic_call_recording: 0,
  premium_call_recording: 0,
  meeting_device: 0,
  is_contact_center_business: false,
  advanced_reporting: false,
  country_code: "",
  activeAddons: 0,
  location: [
    {
      location_name: "",
      number_of_users: 1,
      numbers_to_port: 0,
      additional_numbers: 0,
      plan: {
        id: 1,
        name: "Starter",
        price: "36",
      },
      is_toll_free_required: false,
      total_toll_free_numbers: 1,
      is_primary: true,
    },
  ],
  selected_plan: 4,
};

export const addonsBody = (signupdetails) => {
  return {
    user_uuid: localStorage.getItem("user_id"),
    selected_plan: signupdetails.selected_plan,
    addons: {
      call_recording: {
        basic_call_user: signupdetails.basic_call_user,
        premium_call_user: signupdetails.premium_call_user,
        total_ivr_flow: signupdetails?.total_ivr_flow,
      },
      automated_IVR_call_flow: {
        ivr_flow: signupdetails.ivr_flow,
        ivr_flow_value: signupdetails.ivr_flow_value,
      },
      headsets_handsets: {
        headset1: signupdetails.headset1,
        headset2: signupdetails.headset2,
        headset3: signupdetails.headset3,
        handset: signupdetails.handset,
      },
      meeting_room_devices: {
        meeting_device: signupdetails.meeting_device,
      },
      contact_center: {
        is_contact_center_business: signupdetails.is_contact_center_business,
      },
      advanced_reporting: {
        advanced_reporting: signupdetails.advanced_reporting,
      },
    },
  };
};

export const signupDetailBody = (signupdetails, utm_data) => {
  return {
    signupDetails: {
      utm: utm_data,
      selected_currency: signupdetails.selected_currency,
      user_uuid: localStorage.getItem("user_id"),
      company_name: signupdetails.company_name,
      business_reg_no: parseInt(signupdetails.business_reg_no) || 0,
      address: signupdetails.address,
      city: signupdetails.city,
      post_code: signupdetails.post_code,
      region: signupdetails.region,
      shipping: signupdetails.shipping,
      shipping_address:
        signupdetails.shipping === "Multiple Addresses"
          ? signupdetails.shipping_address
          : signupdetails.address,
      shipping_city:
        signupdetails.shipping === "Multiple Addresses"
          ? signupdetails.shipping_city
          : signupdetails.city,
      shipping_post_code:
        signupdetails.shipping === "Multiple Addresses"
          ? parseInt(signupdetails.shipping_post_code) || 0
          : parseInt(signupdetails.post_code) || 0,
      shipping_region:
        signupdetails.shipping === "Multiple Addresses"
          ? signupdetails.shipping_region
          : signupdetails.region,
      compete_signup: signupdetails.compete_signup,
    },
    payments: {
      utm: utm_data,
      user_uuid: localStorage.getItem("user_id"),
      location_selected_plan: 1,
      total_no_of_user:
        "$" +
        signupdetails?.location?.reduce(
          (a, b) =>
            parseFloat(a) + parseFloat(b.number_of_users * b.plan.price),
          0 + "00"
        ),
      total_phone_number: "9427638447",
      addons_selected_plan: signupdetails.selected_plan,
      total_basic_call_cost:
        signupdetails?.basic_call_user !== 0
          ? "$" + parseInt(signupdetails?.basic_call_user) * 20 + "00"
          : "00",
      total_premium_call_cost:
        +signupdetails?.premium_call_user !== 0
          ? "$" + parseInt(signupdetails?.premium_call_user) * 49 + "00"
          : "$0.00",
      total_headsets_cost:
        "$" +
        (parseInt(signupdetails?.headset1) * 130 +
          parseInt(signupdetails?.headset2) * 490 +
          parseInt(signupdetails?.headset3) * 400) +
        "00",
      total_handsets_cost: "$" + parseInt(signupdetails?.handset) * 490 + "00",
      total_room_devices_cost:
        +signupdetails?.meeting_device !== 0
          ? "$" + parseInt(signupdetails?.meeting_device) * 10 + "00"
          : "$0.00",
      total_setup_cost:
        signupdetails?.ivr_flow === true &&
        signupdetails?.ivr_flow_value !== "free"
          ? signupdetails?.selected_plan === 1
            ? "$0.00"
            : signupdetails?.selected_plan === 2
            ? "$247.5"
            : "$495"
          : "$0.00",
      total_porting_cost:
        "$" +
        signupdetails.location?.reduce(
          (a, b) =>
            Number(a) +
            Number(
              signupdetails.location?.plan?.name === "Starter"
                ? b.numbers_to_port
                : b.numbers_to_port * 0.6 || 0
            ),
          0
        ) +
        phoneNumberPrice.port,
      total_no_provisioning_cost:
        "$" +
        (signupdetails.location?.reduce(
          (a, b) => Number(a) + Number(b.additional_numbers || 0),
          0
        ) /
          10) *
          additionalValue(
            signupdetails.location?.plan?.name,
            signupdetails.selected_currency
          ) +
        "00",
      total_IVR_call_flow_cost:
        "$" +
        signupdetails.location?.reduce(
          (a, b) => Number(a) + Number(b.total_toll_free_numbers || 1),
          0
        ) *
          tollFreeValue(false, signupdetails.selected_currency) +
        tollFreeValue(true, signupdetails.selected_currency) +
        "00",
      charge_id: "",
      payment_status: "",
      client_secret: "",
    },
  };
};

export const locationBody = (signupdetails) => {
  return signupdetails.location.map((item) => {
    return {
      ...item,
      user_uuid: localStorage.getItem("user_id"),
      country_code: signupdetails.country_code,
      location_name: signupdetails?.location_name,
    };
  });
};

export const signupBody = (signupdetails) => {
  return {
    full_phone_number: signupdetails?.full_phone_number,
    first_name: signupdetails?.first_name,
    last_name: signupdetails?.last_name,
    email: signupdetails?.email,
    phone: signupdetails?.phone,
    ip: localStorage.getItem("ip_address"),
    company_name: signupdetails?.company_name,
  };
};

export const totalPayment = (signupdetails) => {
  const planCost = signupdetails?.location?.reduce(
    (a, b) =>
      parseFloat(a) +
      parseFloat(
        b.number_of_users *
          planAmountMap[b.country_code][[planEnum[b.plan.name]]]
      ),
    0
  );
  const additionalNumbersCost =
    (signupdetails.location?.reduce(
      (a, b) => Number(a) + Number(b.additional_numbers || 0),
      0
    ) /
      10) *
    additionalValue(
      signupdetails.location?.plan?.name,
      signupdetails.selected_currency
    );

  const tollFreeNumbersCost =
    signupdetails.location?.reduce(
      (a, b) => Number(a) + Number(b.total_toll_free_numbers || 1),
      0
    ) *
      tollFreeValue(false, signupdetails.selected_currency) +
    tollFreeValue(true, signupdetails.selected_currency);

  const portNumbersCost =
    signupdetails.location?.reduce(
      (a, b) =>
        Number(a) +
        Number(
          signupdetails.location?.plan?.name === "Starter"
            ? b.numbers_to_port
            : b.numbers_to_port * 0.6 || 0
        ),
      0
    ) + phoneNumberPrice.port;

  const addonsCost =
    parseInt(signupdetails?.headset1 || 0) * 130 +
    parseInt(signupdetails?.headset2 || 0) * 490 +
    parseInt(signupdetails?.headset3 || 0) * 400 +
    parseInt(signupdetails?.handset || 0) * 490 +
    parseInt(signupdetails?.meeting_device || 0) +
    parseInt(signupdetails?.basic_call_user || 0) * 20 +
    parseInt(signupdetails?.premium_call_user || 0) * 49 +
    parseInt(signupdetails?.total_ivr_flow || 0) * 10;

  const setupCall =
    signupdetails?.ivr_flow === true && signupdetails?.ivr_flow_value !== "free"
      ? signupdetails?.selected_plan === 1
        ? 0.0
        : signupdetails?.selected_plan === 2
        ? 247.5
        : 495
      : 0.0;
  const additionSetupCall = additionalSetupCost(
    signupdetails.selected_currency,
    signupdetails?.location?.reduce(
      (sum, product) => sum + product.additional_numbers,
      0
    )
  );
  const totalAmount =
    addonsCost +
    setupCall +
    additionalNumbersCost +
    planCost +
    tollFreeNumbersCost +
    portNumbersCost +
    additionSetupCall;
  console.log(totalAmount, "total amount in USD");
  return totalAmount < 37 ? "36" : totalAmount;
};

// dummy price for phone numbers - shall be updated as per client requirement
export const phoneNumberPrice = {
  port: 450,
  tollFree: 10,
  additional: 8,
};
export const additionalSetupCost = (currency, value) => {
  const setupCost = value / 10;
  if (
    currency === "USD" ||
    currency === "AUD" ||
    currency === "NZD" ||
    currency === "GBP" ||
    currency === "CAD"
  ) {
    if (value >= 100) {
      const setupCostValue = parseFloat(value / 100).toFixed(1);
      return (parseInt(setupCostValue.split(".")[1]) + 1) * 60;
    } else {
      return parseInt(setupCost * 15);
    }
  } else if (currency === "SGD") {
    if (value >= 100) {
      const setupCostValue = parseFloat(value / 100).toFixed(1);
      return (parseInt(setupCostValue.split(".")[1]) + 1) * 2000;
    } else {
      return parseInt(setupCost * 20);
    }
  }
};

export const tollFreeValue = (isSetCost, currency) => {
  if (currency === "USD") {
    if (isSetCost) {
      return 350;
    }
    return 40;
  } else if (currency === "AUD") {
    if (isSetCost) {
      return 350;
    }
    return 27;
  } else if (currency === "NZD") {
    if (isSetCost) {
      return 90;
    }
    return 45;
  } else if (currency === "GBP") {
    if (isSetCost) {
      return 25;
    }
    return 25;
  } else if (currency === "CAD") {
    if (isSetCost) {
      return 100;
    }
    return 30;
  } else if (currency === "SGD") {
    if (isSetCost) {
      return 350;
    }
    return 100;
  }
  return 0;
};

export const additionalValue = (isStarter, currency) => {
  if (
    currency === "USD" ||
    currency === "AUD" ||
    currency === "NZD" ||
    currency === "GBP"
  ) {
    if (isStarter === "Starter") {
      return 10;
    } else if (isStarter !== "Starter" && currency === "NZD") {
      return 6.5;
    } else if (isStarter !== "Starter" && currency === "GBP") {
      return 7.5;
    } else if (isStarter !== "Starter") {
      return 6;
    }
  } else if (currency === "CAD") {
    if (isStarter === "Starter") {
      return 15;
    } else {
      return 10;
    }
  } else if (currency === "SGD") {
    if (isStarter === "Starter") {
      return 70;
    } else {
      return 50;
    }
  }
  return 0;
};
export const addLocationTextHelper = (idx, locationArr) => {
  let posText = addLocationEnum[idx];
  if (!posText) posText = "more";

  if (locationArr[idx + 1] === undefined) {
    return `+ ${posText} Location`;
  } else {
    return `- ${posText} Location`;
  }
};

export const addLocationEnum = ["Second", "Third", "Fourth", "Fifth", "Sixth"];

export const dummyConversionRates = {
  USD: {
    AUD: 1.5093202317,
    CAD: 1.3650002621,
    GBP: 0.7945001407,
    NZD: 1.6542602119,
    SGD: 1.351680243,
    USD: 1,
  },

  AUD: {
    AUD: 1,
    CAD: 1.4763300612,
    GBP: 0.859299792,
    NZD: 1.7891821325,
    SGD: 1.4619236576,
    USD: 1.0815602767,
  },

  CAD: {
    AUD: 1.1057288952,
    CAD: 1,
    GBP: 0.5820512734,
    NZD: 1.2119120104,
    SGD: 0.9902417461,
    USD: 0.7326005919,
  },

  NZD: {
    AUD: 0.9123838081,
    CAD: 0.8251424125,
    GBP: 0.4802751919,
    NZD: 1,
    SGD: 0.8170904633,
    USD: 0.6044998198,
  },

  SGD: {
    AUD: 1.1166252074,
    CAD: 1.0098544158,
    GBP: 0.5877870486,
    NZD: 1.2238546953,
    SGD: 1,
    USD: 0.7398199428,
  },

  GBP: {
    AUD: 1.899710465,
    CAD: 1.7180616996,
    GBP: 1,
    NZD: 2.0821396085,
    SGD: 1.7012964174,
    USD: 1.2586530181,
  },
};

export function formatCurrencyAmount(amt, currency, decimals) {
  if (!amt || Number.isNaN(amt)) return `${currencyMap[currency]}0`;
  let convertedAmount = amt * this?.[currency] || 1;
  if (decimals) convertedAmount = convertedAmount.toFixed(decimals);
  else convertedAmount = convertedAmount.toFixed(2);
  return `${currencyMap[currency]}${convertedAmount}`;
}

export function defaultFormatCurrencyAmount(
  amt,
  currency,
  decimals,
  defaultCurrency
) {
  if (!amt || Number.isNaN(amt)) return `${currencyMap[currency]}0`;
  let convertedAmount =
    amt * dummyConversionRates[codeCurrencyMap[defaultCurrency]]?.[currency] ||
    1;
  if (decimals) convertedAmount = convertedAmount.toFixed(decimals);
  else convertedAmount = convertedAmount.toFixed(2);
  return `${currencyMap[currency]}${convertedAmount}`;
}
