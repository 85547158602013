import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Audio } from "../../assets/svg/audio.svg";
import { ReactComponent as Headsets } from "../../assets/svg/headsets.svg";
import { ReactComponent as Laptop } from "../../assets/svg/laptop.svg";
import { ReactComponent as Settings } from "../../assets/svg/settings.svg";

const AutomatedStepper = ({
  activeIndex,
  handleActiveIndex,
  data,
  currency,
}) => {
  const isStepHighlighted = (step) => {
    switch (step) {
      case 1:
        if (data?.ivr_flow || activeIndex === 1) return true;
        break;
      case 2:
        if (
          data?.basic_call_user > 0 ||
          data?.premium_call_user > 0 ||
          activeIndex === 2
        )
          return true;
        break;
      case 3:
        if (
          data?.headset1 > 0 ||
          data?.headset2 > 0 ||
          data?.headset3 > 0 ||
          data?.handset > 0 ||
          activeIndex === 3
        )
          return true;
        break;
      case 4:
        if (data?.meeting_device > 0 || activeIndex === 4) return true;
        break;
      default:
        return false;
    }
  };

  return (
    <Box
      width={"91%"}
      sx={{
        display: { md: "flex", xs: "none" },
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Box
        onClick={() => handleActiveIndex(1)}
        display={"flex"}
        alignItems={"center"}
        sx={{
          maxWidth: "30%",
          cursor: "pointer",
          opacity: isStepHighlighted(1) ? 1 : 0.5,
          // opacity: activeIndex >= 1 && activeIndex !== 0 ? 1 : 0.5,
        }}
      >
        <Box sx={{ width: "51px" }}>
          <Settings />
        </Box>
        <Typography
          sx={{
            mx: "16px",
            color: "#7B83EB",
            fontSize: "16px",
            fontStyle: "normal",
            fontFamily: "Heebo",
            fontWeight: "700",
            lineHeight: "109.375%",
          }}
        >
          Automated Attendants and IVR Call Flows
        </Typography>
      </Box>
      <Box
        onClick={() => handleActiveIndex(2)}
        display={"flex"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
          opacity: isStepHighlighted(2) ? 1 : 0.5,
          // opacity: activeIndex >= 2 && activeIndex !== 0 ? 1 : 0.5,
        }}
      >
        <Box sx={{ width: "51px" }}>
          <Audio />
        </Box>
        <Typography
          sx={{
            mx: "16px",
            color: "#7B83EB",
            fontSize: "16px",
            fontStyle: "normal",
            fontFamily: "Heebo",
            fontWeight: "700",
            lineHeight: "109.375%",
          }}
        >
          Call Recording
        </Typography>
      </Box>
      {data.location.length > 0 &&
        data.location.find((item) => item?.country_code === "AU") &&
        currency === "AUD" && (
          <>
            <Box
              onClick={() => handleActiveIndex(3)}
              display={"flex"}
              alignItems={"center"}
              sx={{
                cursor: "pointer",
                opacity: isStepHighlighted(3) ? 1 : 0.5,
                // opacity: activeIndex >= 3 && activeIndex !== 0 ? 1 : 0.5,
              }}
            >
              <Box sx={{ width: "51px" }}>
                <Headsets />
              </Box>
              <Typography
                sx={{
                  mx: "16px",
                  color: "#7B83EB",
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "109.375%",
                }}
              >
                Headsets & Handsets
              </Typography>
            </Box>
            <Box
              onClick={() => handleActiveIndex(4)}
              display={"flex"}
              alignItems={"center"}
              sx={{
                cursor: "pointer",
                opacity: isStepHighlighted(4) ? 1 : 0.5,
                // opacity: activeIndex >= 4 && activeIndex !== 0 ? 1 : 0.5,
              }}
            >
              <Box sx={{ width: "51px" }}>
                <Laptop style={{ height: "52px" }} />
              </Box>
              <Typography
                sx={{
                  mx: "16px",
                  color: "#7B83EB",
                  fontSize: "16px",
                  fontFamily: "Heebo",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "109.375%",
                }}
              >
                Meeting room devices
              </Typography>
            </Box>
          </>
        )}
    </Box>
  );
};
export default AutomatedStepper;
